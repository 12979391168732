<template>
  <v-container fluid>
    <v-card elevation="0" style="margin-bottom: 10px">
      <v-layout row wrap px-5>
        <v-flex xs7 sm9 md10 style="text-align: center; padding: 5px">
          <v-text-field
            v-model="serveTelToken"
            label="ServeTel Token"
            outlined
            style="padding: 8px 0"
          ></v-text-field>
        </v-flex>

        <v-flex xs5 sm3 md2 style="text-align: center; padding: 5px">
          <v-btn
            style="
              margin-left: 10px;
              margin-top: 8px;
              color: green;
              padding: 28px;
            "
            mt-5
            @click="save(serveTelToken)"
            >SAVE TOKEN</v-btn
          >
        </v-flex>
      </v-layout>

      <v-snackbar v-model="snackbar" :bottom="true" :right="true" timeout="2000"
        >Saved!</v-snackbar
      >
    </v-card>

    <v-card elevation="0" style="margin-bottom: 10px">
      <v-layout mx-1 row style="padding: 2%">
        <v-flex xs12 sm12 md12 style="padding-top: 20px">
          <h3>ServeTel Integration</h3>
        </v-flex>

        <v-flex>
          <p>
            Connect with ServeTel to add Cloud Telephony functions like Call
            Recording and Phone number masking.
          </p>
        </v-flex>
      </v-layout>

      <v-layout xs12 sm12 md12 style="padding-top: 20px">
        <v-flex mt-4 mx-5>
          <h4>How It Works</h4>

          <p>
            If your based in India, you can purchase a plan with ServeTel and
            connect it with GoDial. GoDial will start placing calls using your
            ServeTel account. Outbound number will be shown as per your account,
            call recordings will also be available. We are also working to
            capture incoming call details soon.
          </p>
        </v-flex>
      </v-layout>

      <v-layout xs12 sm12 md12 style="padding-top: 20px">
        <v-flex mt-4 mx-5>
          <h4>Connect Your Team</h4>
          <p>
            Team members must have corrosponding Agents created in Servetel.
            Help us map the agents and members here.
          </p>

          <p>
            <v-card v-for="member in members" :key="member.id">
              <v-card-title>{{ member.name }}</v-card-title>
              <v-card-text>
                <v-col>
                  <v-row
                    ><v-select
                      ><option
                        v-for="serveteluser in _.filter(servetelUsers, (s) => {
                          return s.product_clicktocall == 1;
                        })"
                        :key="serveteluser.id"
                      >
                        {{ serveteluser.name }}
                      </option>
                    </v-select></v-row
                  >
                  <v-row><v-btn primary>SAVE</v-btn></v-row></v-col
                >
              </v-card-text>
            </v-card>
          </p>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md12 style="padding: 5px">
          <v-btn
            style="margin-top: 20px; margin-left: 10px; color: green"
            href="https://servetel.in/"
            target="_blank"
            >GOTO ServeTel</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      members: [],
      serveTelToken: "",
      servetelUsers: [],
      snackbar: false,
      contentTab: null,
      tabsHeader: ["Query", "Headers", "Body", "Path"],
      textTab:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  computed: {
    ...mapState(["ENDPOINT"]),
  },
  created() {
    const self = this;
    self.$http
      .get(`${this.ENDPOINT}/companies/${self.$store.state.user.companyId}`)
      .then((response) => {
        self.serveTelToken = response.body.serveTelToken;
      })
      .catch((err) => {
        self.$swal({ type: "error", text: err.body.error.message });
      });

    self.$http.get(`${self.ENDPOINT}/accounts`).then((response) => {
      self.members = response.body;

      self.$http
        .get(`https://api.servetel.in/v1/agents`, {
          headers: { authorization: self.serveTelToken },
        })
        .then((response) => {
          self.servetelUsers = response.body;
        });
    });
  },
  methods: {
    save() {
      this.$http
        .patch(
          `${this.ENDPOINT}/companies/${this.$store.state.user.companyId}`,
          {
            serveTelToken: this.serveTelToken,
          }
        )
        .then((response) => {
          this.snackbar = true;
        })
        .catch((err) => {
          this.$swal({ type: "error", text: err.body.error.message });
        });
    },
  },
};
</script>
<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
table {
  width: 100%;
}
pre {
  overflow-x: scroll;
}
</style>
